import { render, staticRenderFns } from "./CategoriesTab.vue?vue&type=template&id=aef281fc&scoped=true"
import script from "./CategoriesTab.vue?vue&type=script&lang=js"
export * from "./CategoriesTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aef281fc",
  null
  
)

export default component.exports